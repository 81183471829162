// _general-blog.scss

.top-bar {
  background: $black;
  ul {
    background: inherit;
  }
}

code {
  display: block;
  width: 100%;
  overflow: auto;
}

.button.primary {
  color: $white;
  &:hover {
    color: $white;
  }
}
.hero {
  padding: 1rem;
  @include breakpoint(medium) {
    padding: 3rem;
  }
  background-color: $black;
  background-image: url(../medias/img/curves.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  .row {
    position: relative;
  }
  h1,
  .logo-container {
    color: $white;
    font-style: italic;
    font-size: 1rem;
  }
  .logo {
    display: inline-block;
    background: url(../medias/img/itnetwork.svg) no-repeat 50% 50%;
    background-size: cover;
    width: 200px;
    height: 70px;
    text-indent: -9999em;
  }
}
.itn-link {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
  position: absolute;
  right: 0;
  bottom: -$global-margin;
  margin: $global-margin;
  span {
    color: transparent;
    transition: color .4s ease;
    &:last-child:after {
      display: inline-block;
      font-family: "FontAwesome";
      content: "\f08e";
      margin-left: 1rem;
    }
  }
  &:hover {
    color: $white;
    span {
      color: $primary-color;
    }
  }
}

h1.page-title {
  margin-top: 2rem;
  font-size: 1rem;
}
.blog-post {
  background-color: $white;
  margin-top: $global-margin;
  margin-bottom: $global-margin;
  @include breakpoint(medium) {
    margin-top: $global-margin*2;
    margin-bottom: $global-margin*2;
  }
  @include breakpoint(large) {
    margin-top: $global-margin*4;
    margin-bottom: $global-margin*4;
  }
  .img {
    text-align: center;
    margin-bottom: 2rem;
  }
  section {
    font-size: 20/16 * 1rem;
    font-family: "Georgia", serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 2rem;
      margin-bottom: 2rem;
      border-bottom: solid 1px $primary-color;
      font-weight: 600;
      color: $black;
    }
  }
  p {
    line-height: 1.5em;
    margin-bottom: 1.5rem;
  }
}
article h1 {
  color: $black;
  border-left: solid 4px $primary-color;
  padding-left: $global-padding;
}
.meta {
  color: $dark-gray;
  font-weight: normal;
  text-align: right;
  margin-bottom: $global-margin;
  a {
    color: inherit;
    &:hover {
      color: $black;
      .fa-twitter {
        color: #1da1f2;
      }
    }
  }
}
time {
  &:before {
    display: inline-block;
    font-family: "FontAwesome";
    content: "\f073";
  }
}
.continue-reading {
  font-weight: bold;
  a:after {
    display: inline-block;
    font-family: "FontAwesome";
    content: "\f178";
    color: $black;
    font-weight: 100;
    font-size: $small-font-size;
  }
}
footer.metas-footer {
  display: block;
  padding-bottom: 1rem;
  border-bottom: dotted 1px $light-gray;
  p {
    display: inline-block;
    color: $medium-gray;
  }
  ul {
    display: inline-block;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    li {
      display: inline-block;
      margin-left: 1rem;
      padding-left: 0;
      &:before {
        display: inline-block;
        content: "\2022";
        font-weight: bold;
        margin-right: 1rem;
      }
      &:first-child:before {
        display: none;
      }
    }
  }
}
.pagination {
  margin-top: $global-margin*2;
  margin-bottom: $global-margin*4;
  text-align: center;
}
.main-footer {
  font-size: $small-font-size;
  text-align: center;
  padding-top: $global-margin*2;
  padding-bottom: $global-margin*2;
  border-top: solid 1px $primary-color;
}
.previous-post,
.next-post {
  font-weight: bold;
  &:after,
  &:before {
    display: inline-block;
    font-family: "FontAwesome";
    font-weight: normal;
  }
}
.previous-post:before {
  content: "\f177";
}
.next-post:after {
  content: "\f178";
}

ul.all-tags-list {
  display: block;
  list-style-type: none;
  margin: 2rem 0;
  text-align: center;
  font-size: $small-font-size;
  line-height: 1;
  li {
    display: inline;
    &:before {
      display: inline-block;
      content: "";
      font-weight: bold;
      margin-left: .5rem;
      margin-right: .5rem;
    }
    &:first-child:before {
      display: none;
    }
  }
}

$elements: 50;
@for $i from 1 to $elements {
  .tag-weight-#{$i} {
     font-size: calc(9px + #{$i}px);
  }
}

.share-me {
  text-align: right;
  p {
    display: inline-block;
    font-size: 80%;
    font-weight: bold;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    li {
      display: inline-block;
    }
  }
  a {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $primary-color;
    border-radius: .1rem;
    transition: background .3s ease-in-out;
    &:hover {
      background: rgba($primary-color,.8);
    }
  }
}

/// BOUTON RDV

.btnRDV {
  @include breakpoint(medium) {
    margin-left: -28% !important;
  }
  @include breakpoint(large) {
    margin-left: -38% !important;
  }
  .btnfixeRDV {
    background-color: $primary-color;
    color: $white;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 25px;
    padding: 0.5em;
    border: 1px solid $white;
    text-decoration: none;
    font-size: 1em;
    bottom: 1em;
    z-index: 5;
    transition: background-color 0.5s;
    img {
      width: 50px;
      height: auto;
      border-radius: 100%;
      border: solid .25rem $black;
      margin-right: 1rem;
      box-shadow: 0 0 1rem rgba(0,0,0,0);
      transition: box-shadow .3s, border-color .3s;
      @include breakpoint(medium) {
        width: 70px;
        border: solid .5rem $black;
      }
    }
  }
  .btnfixeRDV:hover {
    border: 1px solid $primary-color;
    background-color: $white;
    color: $primary-color;
    cursor: pointer;
  }
}